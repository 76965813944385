import { TimelineMax } from 'gsap'
import React from "react"
import { Jumbotron } from "react-bootstrap"
import styled from 'styled-components'
import { LoginLayout } from '../../components/layout'
import { SubHeading } from '../../components/LocaleFields'
import { Seo } from '../../components/navigation'
import OAuth from "../../components/OAuth"
import { GrowCol, GrowRow } from "../../components/utils"
import { slideInFromBottomConfig } from "../../utils/Animate"

const providers = ['facebook', 'google']

const ButtonContainer = styled.div`width:300px;`

const IndexPage = () => {

    const refTitle = React.useRef(null)
    const refSubtitle = React.useRef(null)
    const refButtons = React.useRef(null)

    React.useEffect(() => {
        new TimelineMax()
            .from(refTitle.current, slideInFromBottomConfig)
            .from(refSubtitle.current, slideInFromBottomConfig, 0.3)
            .from(refButtons.current, slideInFromBottomConfig, 0.6)
    }, [])

    return <LoginLayout>
        <Seo.Login />
        <Jumbotron className="text-center bg-white">
            <SubHeading innerRef={refTitle}>Sign up for SubClub</SubHeading>
            <div className="mt-4 mb-5" ref={refSubtitle}>Join the <b>most powerful subscription tracking platform</b> in the world<br /> and manage your subscriptions within seconds.</div>
            <GrowRow className="justify-content-center">
                <div ref={refButtons}>
                    <ButtonContainer>
                        <GrowCol>
                            {providers.map((provider) => <OAuth key={provider} provider={provider} />)}
                        </GrowCol>
                    </ButtonContainer>
                </div>
            </GrowRow>
        </Jumbotron>
    </LoginLayout>
}

export default IndexPage
