
import React, { useContext, useState } from "react"
import GlobalStoreContext from '../context/GlobalStoreContext'
import SubscriptionListItem from './SubscriptionListItem'
import SubscriptionCreate from './SubscriptionCreate'
import { Card, ListGroup, Jumbotron } from "react-bootstrap"
import { fadeIn, ANIMATION_DURATION_SECS } from '../utils/Animate'

const SubscriptionList = ({ showEdit = false, mode = "details", filter = "none", ...props }) => {
    const { records = {} } = React.useContext(GlobalStoreContext)
    const [edit, setEdit] = useState(null)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [filteredRecords, setFilteredRecords] = useState([])

    // manage references
    const refs = React.useRef([])
    const refFns = i => el => refs.current[i] = el
    React.useEffect(() => { if (filteredRecords !== null) refs.current = refs.current.slice(0, Object.values(filteredRecords).length) }, [])
    React.useEffect(() => {
        console.debug('sliding in from right...')
        fadeIn(refs.current, ANIMATION_DURATION_SECS)
    }, [filteredRecords])

    React.useEffect(() => {
        console.debug('rendering subscriptions list', records && Object.values(records))
        if (filter === "none") setFilteredRecords(Object.values(records)
            // sort by title case insensitive...
            .sort((a, b) => a.title.localeCompare(b.title, 'en', { 'sensitivity': 'base' }))
        )
        else if (filter === "thisweek") setFilteredRecords(Object.values(records)
            .filter(r => r._nextInstanceDaysUntil < 7)
            // sort by days until asc, then amount desc...
            .sort((a, b) => {
                const diff = a._nextInstanceDaysUntil - b._nextInstanceDaysUntil
                if (diff !== 0) return diff
                return b.amount - a.amount
            })
        )
    }, [records, setFilteredRecords])

    const handleEdit = record => {
        setEdit(record)
        handleShow()
    }

    // todo - filter list by 'filter' (none, thisweek, thismonth, thisyear)

    return <>

        <SubscriptionCreate {...{ edit, show, handleClose }} />

        {Object.keys(records).length === 0
            ? <Jumbotron className="text-italic text-muted text-center mb-0">
                <h4>{"You don't have any subscriptions yet."}</h4>
                <div>Subscriptions let you track and see the services you currently subscribe to.</div>
            </Jumbotron>
            :
            <Card>
                <ListGroup variant="flush">
                    {filteredRecords.map((record, i) => {
                        return <SubscriptionListItem key={record.id} innerRef={refFns(i)} handleEdit={handleEdit} showEdit={showEdit} mode={mode} {...record} />
                    })}
                </ListGroup>
            </Card>
        }
        {/* <pre className="d-none">{JSON.stringify(records, null, 2)}</pre> */}
    </>
}
export default SubscriptionList