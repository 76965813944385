
const intl = require('intl')

const sum = (stringFloats) => {
    return stringFloats.reduce((prev, curr) => parseFloat(curr) + prev, 0)
}

const format = (amountFloat, language, currency, minimumFractionDigits = 2) => {
    return new intl.NumberFormat(language, { style: 'currency', currency, minimumFractionDigits }).format(amountFloat)
}

module.exports = {
    sum,
    format
}