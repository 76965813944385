import React from "react";
import { VictoryLabel, VictoryPie } from 'victory';
import { GlobalStoreContext } from "../../context/GlobalStoreContext";
import { sum } from "../../utils/currency";

export const WeeklyPieChart = ({ innerRef, ...props }) => {
    // magic
    const { store } = React.useContext(GlobalStoreContext)
    const filteredRecords = Object.values(store.records).filter(r => r._nextInstanceDaysUntil < 7)
    const total = sum(filteredRecords.map(r => r.amount)).toFixed(0)

    const data = filteredRecords.map(r => ({ x: `${r.title}`, y: parseInt(r.amount) })).sort((a, b) => a.y - b.y)
    const radiusAdd = total < 10 ? 0 : total < 100 ? 10 : total < 1000 ? 20 : total < 10000 ? 30 : 0
    // chart
    return <>
        <svg viewBox="-50 -40 500 500" ref={innerRef}>
            <VictoryPie
                standalone={false}
                colorScale={["navy", "orange", "gold", "cyan", "tomato"]}
                style={{
                    data: {
                        fillOpacity: 0.6,
                    },
                    labels: {
                        fill: "black",
                        padding: 20
                    }
                }}
                innerRadius={50 + radiusAdd}
                padAngle={2}
                data={data}
                animate={{ duration: 2000, delay: 2000, onLoad: 2000 }}
            />
            {<VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 50 }}
                x={200} y={200}
                text={`$${total}`}
            />}
        </svg>
    </>
}
