import React, { useState, useEffect } from 'react'
import { FaFacebookSquare, FaGoogle } from 'react-icons/fa'
import firebase, { API_URL } from '../config/config'
import styled from 'styled-components'
import { Button } from "react-bootstrap"
import { GlobalStoreContext } from '../context/GlobalStoreContext'

const FacebookIcon = styled(FaFacebookSquare)`color:white;`
const GoogleIcon = styled(FaGoogle)`color:white;`

const OAuth = (props) => {

  const { setLoadingStatus, clearLoadingStatus } = React.useContext(GlobalStoreContext)

  const [user, setUser] = useState({})
  const [err, setErrorMessage] = useState(null)

  const { provider } = props

  React.useEffect(() => { clearLoadingStatus() }, [])

  // using firebase
  const loginFB = () => {
    setLoadingStatus("Authenticating with Facebook...")
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(function (result) {
        // clearLoadingStatus()
        setErrorMessage(null)
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken
        // The signed-in user info.
        var user = result.user
        console.log('FB User', user)
        // ...
      })
      .catch(function (error) {
        clearLoadingStatus()
        console.log("error fb", error)
        // Handle Errors here.
        var errorCode = error.code
        var setErr = error.message
        // The email of the user's account used.
        var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential
        // ...
        setErrorMessage(`${setErr} - ${email}`)
      })
  }

  const loginGoogle = () => {
    setLoadingStatus("Authenticating with Google...")
    firebase.auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        // clearLoadingStatus()
        setErrorMessage(null)
        console.log("google", result)
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      })
      .catch((err) => {
        clearLoadingStatus()
        console.log("error signing in with google", err)
      })
  }

  const CONFIG = {
    facebook: {
      colour: '#4267B2',
      Icon: FacebookIcon,
      onClick: loginFB
    },
    google: {
      colour: '#CC3F23',
      Icon: GoogleIcon,
      onClick: loginGoogle
    }
  }

  const Config = CONFIG[provider]

  return (
    <>
      <Button
        className="d-flex flex-row align-items-center my-2"
        onClick={Config.onClick}
        style={{ background: Config.colour, borderWidth: 0 }}
      >
        <Config.Icon />
        <div className="text-center ml-3 flex-grow-1">Continue with {provider}</div>
      </Button>
      {
        err &&
        <div className="alert alert-danger">{err}</div>
      }
    </>
  )
}

export default OAuth 
