import { Router } from "@reach/router"
import React from "react"
import { navTo, PATHS } from "../components/navigation"
import { GlobalStoreContext } from "../context/GlobalStoreContext"
import Dashboard from "./app/dashboard"
import Friends from "./app/friends"
import Login from "./app/login"
import Profile from "./app/profile"
import Subscriptions from "./app/subscriptions"

const PrivateRoute = ({ component: Component, ...props }) => {
    const { store: { isLoggedIn = null } = {} } = React.useContext(GlobalStoreContext)
    if (isLoggedIn === false) {
        console.log('Not authenticated on private page, redirecting to login...')
        navTo.login()
        return null
    } else {
        return <Component {...props} />
    }
}
const AuthCheckRoute = ({ component: Component, ...props }) => {
    const { store: { isLoggedIn = null } = {}, clearLoadingStatus } = React.useContext(GlobalStoreContext)
    if (isLoggedIn === true) {
        console.log('Already authenticated, redirecting to dashboard...')
        clearLoadingStatus()
        navTo.dashboard()
        return null
    } else {
        return <Component {...props} />
    }
}

// Hack fix for crappy reach/router -> https://github.com/reach/router/issues/63
const RouterComponent = props => <>{props.children}</>

const App = () => {
    return <>
        <Router primary={false} component={RouterComponent}>
            <PrivateRoute component={Profile} path={PATHS.profile} />
            <PrivateRoute component={Dashboard} path={PATHS.dashboard} />
            <PrivateRoute component={Subscriptions} path={PATHS.subscriptions} />
            <PrivateRoute component={Friends} path={PATHS.friends} />
            <AuthCheckRoute component={Login} path={PATHS.login} />
        </Router>
    </>
}
export default App