
import React, { useContext, useEffect, useState } from "react"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import uuid from 'uuid/v4'
import { GlobalStoreContext } from '../context/GlobalStoreContext'
import Input from './Input'
import Toggle from './Toggle'
import { calcNextDate, DOTW_OPTIONS, formatFull, FREQUENCY_OPTIONS, MOTY_OPTIONS } from './utils'

const prepend = text => () => <>
    <InputGroup.Prepend>
        <InputGroup.Text>{text}</InputGroup.Text>
    </InputGroup.Prepend>
</>

const SubscriptionCreate = ({ edit = null, show, handleClose, ...props }) => {
    const defaultState = {
        title: '',
        amount: '',
        frequency: FREQUENCY_OPTIONS[1].value,
        dotw: DOTW_OPTIONS[0].value,
        dotm: '1',
        moty: MOTY_OPTIONS[0].value,
    }

    const [state, setState] = useState(defaultState)
    const [nextDate, setNextDate] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const { putRecord, deleteRecord } = useContext(GlobalStoreContext)

    useEffect(() => {
        const tmp = calcNextDate(state)
        if (tmp) setNextDate(formatFull(tmp))
    }, [state])

    useEffect(() => {
        if (edit !== null) {
            setState(edit)
            setIsEdit(true)
        }
    }, [edit])

    const onChange = ({ target: { name, value } = {} }) => {
        if (name === 'dotm' && value !== '' && (!/^\d+$/.test(value) || parseInt(value) > 31 || parseInt(value < 1))) return null // dotm must only be 0-9 or blank
        setState(s => ({ ...s, [name]: value }))
    }

    const onFocus = e => {
        const { target: htmlRef } = e
        htmlRef.focus()
        setTimeout(() => htmlRef.select())
    }

    const autoFocus = () => {
        refs.title.focus()
        refs.title.select()
    }

    const handleSubmit = e => {
        e.preventDefault()
        saveRecordAndClearForm()
        handleClose()
    }

    const saveRecordAndClearForm = () => {
        const record = { ...state }
        if (!record.id) record.id = uuid()
        console.debug('saveRecordAndClearForm', record)
        putRecord(record)
        setState(defaultState) // clear fields
    }

    const handleCreateAnother = () => {
        saveRecordAndClearForm()
        refs.title.focus()
    }

    const handleDeleteAndClose = () => {
        const record = { ...state }
        deleteRecord(record)
        handleClose()
    }

    const refs = { title: null }

    return <>

        <Modal show={show} onHide={handleClose} onEntered={autoFocus}>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit Subscription' : 'New Subscription'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input {...{ name: 'title', value: state.title, innerRef: x => refs.title = x, autoFocus: true, Prepend: prepend('Title'), onFocus, onChange }} />
                    <Input {...{ name: 'amount', value: state.amount, type: 'number', Prepend: prepend('$'), onFocus, onChange }} />
                    <Toggle {...{ name: 'frequency', value: state.frequency, onChange, options: FREQUENCY_OPTIONS }} />

                    {state.frequency === 'weekly' && <>
                        <Toggle {...{ name: 'dotw', value: state.dotw, onChange, className: 'ToggleItems-dotw', options: DOTW_OPTIONS }} />
                    </>}

                    {state.frequency === 'monthly' && <>
                        <Input {...{ name: 'dotm', value: state.dotm, type: 'number', Prepend: prepend('Day of the month'), onFocus, onChange }} />
                    </>}

                    {state.frequency === 'yearly' && <>
                        <Input {...{ name: 'dotm', value: state.dotm, type: 'number', Prepend: prepend('Day of the month'), onFocus, onChange }} />
                        <Toggle {...{ name: 'moty', value: state.moty, onChange, className: 'ToggleItems-moty', options: MOTY_OPTIONS }} />
                    </>}

                    <div className="text-muted mx-3">Next occurrence: {nextDate ? nextDate : '-'}</div>

                    {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}

                </Modal.Body>
                <Modal.Footer>
                    {isEdit
                        ? <>
                            <Button variant="outline-danger" className="mr-auto" onClick={handleDeleteAndClose}>Delete</Button>
                            <Button variant="primary" type="submit">Save</Button>
                        </>
                        : <>
                            <Button variant="outline-primary" className="mr-auto" onClick={handleCreateAnother}>Create Another</Button>
                            <Button variant="primary" type="submit">Create</Button>
                        </>}
                </Modal.Footer>
            </Form>
        </Modal>

    </>
}
export default SubscriptionCreate