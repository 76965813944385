import React, { useEffect, useState } from "react"
import { Button, Jumbotron } from "react-bootstrap"
import styled from 'styled-components'
import FriendAdd from "../../components/FriendAdd"
import { PrivateLayout } from '../../components/layout'
import { SubHeading } from '../../components/LocaleFields'
import { Seo } from "../../components/navigation"
import { GrowRowCol } from "../../components/utils"
import { GlobalStoreContext } from "../../context/GlobalStoreContext"
import { acceptFriend, getFriends } from '../../utils/API'

const IndexPage = () => {
  const { store: { profile } = {} } = React.useContext(GlobalStoreContext)

  const [show, setShow] = useState(false)
  const [friends, setFriends] = useState(null)
  const [friendInvite, setFriendInvite] = useState(null)
  const handleClose = () => {

    setShow(false)
    // hack
    setTimeout(() => updateFriendList(), 2000)

  }
  const handleShow = () => setShow(true)

  const ProfileImage = styled(({ className = '', ...props }) => <img className={`${className} rounded-circle border border-secondary mx-2`} {...props} />)`
  height: 40px;
  width: 40px;
`

  const updateFriendList = () => {
    getFriends(profile.uid)
      .then(res => {
        console.log("result", res)
        setFriends(res.data.friends)
        setFriendInvite(res.data.friend_request)
      })
      .catch(err => {
        const { message, stack } = err
        console.debug('could not get user friends from backend', { error: message, stack })
      })
  }

  const handleAcceptFriend = (id) => {
    acceptFriend(profile.uid, id)
      .then(res => {
        console.log("result", res)
      })
      .catch(err => {
        const { message, stack } = err
        console.debug('could not accept friend', { error: message, stack })
      })
  }



  useEffect(() => {
    updateFriendList()

  }, [profile])

  return <PrivateLayout>
    <Seo.Friends />
    <Jumbotron className="bg-white px-0 py-0 py-md-4">
      <GrowRowCol className="mb-3">
        <SubHeading className="flex-grow-1">your friends</SubHeading>
        <Button variant="primary mt-3 mt-md-0" onClick={handleShow}>Add a Friend</Button>
      </GrowRowCol>

      <FriendAdd {...{ show, handleClose }} />

      <Jumbotron className="mb-0">
        {
          friendInvite &&
          (
            <div>
              <h3 className="py-3">Friends requests</h3>
              {
                Object.keys(friendInvite.requestors).map(f => {
                  return <div key={f} className="py-2">
                    <ProfileImage src={friendInvite.requestors[f].photoURL} />
                    {friendInvite.requestors[f].displayName}
                    <Button variant="primary mx-2" onClick={() => handleAcceptFriend(f)}>Confirm</Button>
                    <Button variant="secondary mx-2">Reject</Button>
                  </div>
                })
              }
            </div>
          )
        }
        {
          friends &&
          (
            <div>
              <h3 className="py-3">Invite pending</h3>
              {
                Object.keys(friends.invite).map(f => {
                  return <div key={f} className="py-2"><ProfileImage src={friends.invite[f].photoURL} />{friends.invite[f].displayName}</div>
                })
              }
              <h3 className="py-3">Friends</h3>
              {
                Object.keys(friends.accepted).map(f => {
                  return <div key={f} className="py-2"><ProfileImage src={friends.accepted[f].photoURL} />{friends.accepted[f].displayName}</div>
                })
              }
            </div>
          )

        }
        {
          friends === null &&
          (
            <div className="text-italic text-muted text-center ">
              <h4>Your friends are waiting for you! Invite a friend now...</h4>

              <div>(Coming soon)</div>
            </div>
          )
        }

      </Jumbotron>

    </Jumbotron>

  </PrivateLayout>
}

export default IndexPage
