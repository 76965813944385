import { gsap } from 'gsap'
import React from "react"
import { Col, Jumbotron, Row } from "react-bootstrap"
import { WeeklyPieChart } from "../../components/graphs/WeeklyPieChart"
import { CurvedUpArrow } from "../../components/Images"
import { PrivateLayout } from '../../components/layout'
import { ShortCurrency, SubHeading } from '../../components/LocaleFields'
import { Seo } from '../../components/navigation'
import SubscriptionList from "../../components/SubscriptionList"
import { GrowRowCol } from "../../components/utils"
import { GlobalStoreContext } from "../../context/GlobalStoreContext"
import { ANIMATION_DURATION_SECS } from "../../utils/Animate"
import { sum } from "../../utils/currency"

const IndexPage = () => {

  const refTotal = React.useRef(null)
  const refGraph = React.useRef(null)

  // magic
  const { store, records } = React.useContext(GlobalStoreContext)
  const filteredRecords = Object.values(store.records).filter(r => r._nextInstanceDaysUntil < 7)
  const total = sum(filteredRecords.map(r => r.amount))

  React.useEffect(() => {
    gsap.from(refTotal.current, { opacity: 0, duration: ANIMATION_DURATION_SECS })
    gsap.from(refGraph.current, { opacity: 0, duration: ANIMATION_DURATION_SECS })
  }, [records])

  return <PrivateLayout>
    <Seo.Dashboard />
    {filteredRecords.length > 0
      ? <>
        <Jumbotron className="bg-white px-0 py-0 py-md-4">
          <GrowRowCol className="mb-3">
            <SubHeading className="flex-grow-1">Due this week</SubHeading>
          </GrowRowCol>

          {/* return <Layout authorised={true}>
    <Jumbotron className="px-0 py-0 bg-white">
      <GrowRowCol className="mb-3 mb-lg-0">
        <SubHeading>Outgoings</SubHeading>
        <Button variant="success">Add a Subscription</Button>
      </GrowRowCol> */}
          <Row>
            {/* Maybe make this categorised... assuming we have categories. Maybe bar graph for individual items. */}
            <Col xs={12} md={6} className="mb-3">
              <WeeklyPieChart innerRef={refGraph} />
            </Col>
            <Col xs={12} md={6}>

              <Jumbotron ref={refTotal} className="text-center m-0 mb-3 p-4">
                <h1><ShortCurrency value={total * -1} /></h1>
                <div className="text-muted">total due this week</div>
                {/* <div className="text-muted">(<UpIcon />+$20 avg)</div> */}
              </Jumbotron>

              {/* <SubHeading className="my-3">due this week</SubHeading> */}
              <SubscriptionList showEdit={false} mode="countdown" filter="thisweek" />

            </Col>

            {/* <Col xs={12} md={4} className="mb-3">
          <Jumbotron className="text-center m-0 p-4">
            <h1><ShortCurrency value={-420} /></h1>
            <div className="text-muted">this week</div>
            <div className="text-muted">(<UpIcon />+$20 avg)</div>
          </Jumbotron>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Jumbotron className="text-center m-0 p-4">
            <h1><ShortCurrency value={-420 * 4} /></h1>
            <div className="text-muted">this month</div>
            <div className="text-muted">(<DownIcon />-$120 avg)</div>
          </Jumbotron>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Jumbotron className="text-center m-0 p-4">
            <h1><ShortCurrency value={-420 * 52} /></h1>
            <div className="text-muted">this year</div>
          </Jumbotron>
        </Col>
        <Col>
          <Jumbotron className="text-center m-0 bg-info text-white">
            Pretty Graph
        </Jumbotron>
        </Col> */}

          </Row>
          {/* <Row>
        <Col xs={12} md={6}>
          <SubHeading className="my-3">friends recent activity</SubHeading>
          <Jumbotron className="text-center m-0 text-muted">
            No recent activity
        </Jumbotron>
        </Col>
      </Row> */}
        </Jumbotron>
      </>
      : <>
        <Jumbotron className="text-italic text-muted text-center py-3">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="py-5">
              <h4 className="mb-3">Welcome to the dashboard!</h4>
              <div className="text-115">
                <p>This page shows you an overview of your current bills.</p>
                <p>There&apos;re no subscriptions yet,<br />so let&apos;s start by clicking on &quot;Subscriptions&quot;...</p>
              </div>
            </Col>
            <Col md={{ span: 2 }} className="d-none d-md-block">
              <CurvedUpArrow />
            </Col>
          </Row>
        </Jumbotron>
      </>}
  </PrivateLayout>
}

export default IndexPage
