import React, { useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Modal, Button } from "react-bootstrap"
import axios from 'axios'
import styled from 'styled-components'
import { GlobalStoreContext } from "../context/GlobalStoreContext"
import { addFriend, searchUser } from '../utils/API'

import 'react-bootstrap-typeahead/css/Typeahead.css'


const FriendAdd = ({ show, handleClose, ...props }) => {

  const { store: { profile } = {} } = React.useContext(GlobalStoreContext)



  const ProfileImage = styled(({ className = '', ...props }) => <img className={`${className} rounded-circle border border-secondary mx-2`} {...props} />)`
    height: 40px;
    width: 40px;
  `

  const FullWidth = styled.div`
    width: 100%;
  `

  const [state, setState] = useState({
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: []
  })

  const [addUser, setAddUser] = useState(null)

  const autoFocus = () => {

  }

  const _handleSearch = (query) => {
    console.log("query", query)
    setState(ps => {
      ps.isLoading = true
      return { ...ps }
    })
    searchUser(query)
      .then(res => {
        // user is logged in and has data...
        console.log("result", res)
        setState(ps => {
          ps.isLoading = false
          ps.options = res.data.result
          return { ...ps }
        })
      })
      .catch(err => {
        const { message, stack } = err
        console.debug('could not get user data from backend', { error: message, stack })
        setState(ps => {
          ps.isLoading = false
          ps.options = []
          return { ...ps }
        })
      })
  }

  const updateSelected = (selected) => {
    setAddUser(selected)
  }
  const handleAddFriend = () => {
    console.log("add", addUser)
    addFriend(profile.uid, addUser)
    handleClose()
  }

  return <>
    <Modal show={show} onHide={handleClose} onEntered={autoFocus}>
      <div className="container py-3">
        <div className="d-flex flex-grow-1 searchBoxContainer">
          <AsyncTypeahead
            {...state}
            id='user'
            labelKey={option => `${option.displayName}`}
            minLength={2}
            onSearch={_handleSearch}
            onChange={updateSelected}
            placeholder="Search for a local user..."
            renderMenuItemChildren={(option, props) => (
              <>
                <ProfileImage src={option.photoURL} />
                {option.displayName}
              </>
            )}
          />
          <Button variant="primary mx-3 mt-md-0" onClick={handleAddFriend}>Add</Button>
        </div>
      </div>

    </Modal>
  </>
}

export default FriendAdd