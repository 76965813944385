import React from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
// import { Typeahead } from 'react-bootstrap-typeahead'

export const Input = ({ name, value, type = 'text', innerRef: ref, Prepend = null, ...props }) => {
    return <>
        <Form.Group as={Col} controlId={name} className="px-0">
            <InputGroup>
                {Prepend && <Prepend />}
                <Form.Control
                    size="lg"
                    placeholder={`Enter ${name}`}
                    {...{ name, value, type, ref }}
                    {...props} />
            </InputGroup>
        </Form.Group>
    </>
}

export const SimpleInput = ({ name, state, ...props }) => <Input {...{ name, value: state[name], Prepend: prepend(name) }} {...props} />

export const prepend = text => () => <>
    <InputGroup.Prepend>
        <InputGroup.Text className="text-capitalize">{text}</InputGroup.Text>
    </InputGroup.Prepend>
</>

// export const Dropdown = ({ name, options, ...props }) => <Form.Group controlId={`form_${name}`}>
//     <Typeahead
//         id={name}
//         clearButton
//         multiple={false}
//         options={options}
//         placeholder={placeholder}
//         onChange={selected => onChange && onChange({ target: { name, value: multiple ? selected : (selected.length > 0 ? selected[0] : null) } })}
//         bsSize={size}
//         selected={otherProps.value}
//         {...otherProps}
//     />
// </Form.Group>

export default Input