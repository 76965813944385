
import React from "react"
import { Button, ButtonGroup, Col, ListGroup, Row } from "react-bootstrap"
import { FaPen as EditIcon } from 'react-icons/fa'
import styled from 'styled-components'
import { calcNextDate, formatInXDays } from './utils'

const EllipsisFix = styled.span`min-width: 0;`

const views = (desktop, mobile) => <>
    <span className="d-sm-none">{mobile}</span>
    <span className="d-none d-sm-inline">{desktop}</span>
</>

const frequencyToLabel = {
    weekly: views('/ week', '/ wk'),
    monthly: views('/ month', '/ mth'),
    yearly: views('/ year', '/ yr')
}

// mode = details / countdown
const SubscriptionListItem = ({ innerRef, handleEdit, showEdit = true, mode = "details", ...record }) => {

    const { id, title, amount, frequency, _nextInstanceDaysUntil, dotw, dotm, moty } = record
    return <>
        <ListGroup.Item key={id} className="py-1" ref={innerRef} style={{ opacity: 0 }}>
            <Row className="flex-nowrap">
                <Col className="text-125 p-1 p-md-2 d-flex align-items-center text-nowrap flex-shrink-1 text-truncate" xs="auto" >
                    <EllipsisFix className="text-truncate">{title}</EllipsisFix>
                </Col>

                {mode === 'countdown' &&
                    <Col className="text-125 p-1 p-md-2 d-flex align-items-center text-nowrap justify-content-end">
                        <span className="text-primary">${amount}</span>&nbsp;
                        {formatInXDays(_nextInstanceDaysUntil)}
                    </Col>}

                {mode === 'details' &&
                    <Col className="text-125 p-1 p-md-2 d-flex align-items-center text-nowrap justify-content-end">
                        <span className="text-muted mr-2">${amount}</span>
                        <span className="text-primary">{frequencyToLabel[frequency]}</span>
                    </Col>}

                {showEdit === true && <Col className="p-1 p-md-2 d-flex justify-content-center" xs="auto">
                    <ButtonGroup size="sm" className="flex-row" noshadow="">
                        <Button className="flex-grow-1" variant="outline-primary" onClick={() => handleEdit(record)}><EditIcon /></Button>
                    </ButtonGroup>
                </Col>
                }
            </Row>
        </ListGroup.Item>
    </>
}

export default SubscriptionListItem