import React from "react"
import { Form, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

export const BooleanToggle = styled(({
    name = '',
    value = false,
    className = '',
    onChange = () => console.log(`No onToggle handler configured`),
    ...props
}) => <>
        <Form.Check
            type="switch"
            id={name}
            name={name}
            label={value ? 'Active' : 'Disabled'}
            className={`cursor-pointer ${className}`}
            checked={value}
            onChange={() => onChange({ target: { name, value: !value } })}
            {...props}
        />
        {/* <pre>x{JSON.stringify(value, null, 2)}x</pre> */}
    </>)`
& * { cursor: pointer !important; }
width: 100px !important;
`

export const SimpleBooleanToggle = ({ label = '', name, state, onChange, ...props }) => {
    return <Form.Group as={Row} controlId={`form_${name}`} className="my-0">
        <Col className="flex-grow-0">
            <BooleanToggle {...{ name, value: state[name], onChange }} {...props} />
        </Col>
        <Col>
            <Form.Label className="text-capitalize">
                {label}
            </Form.Label>
        </Col>
    </Form.Group>
}

export default BooleanToggle