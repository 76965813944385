import React, { useState } from "react"
import { Button, ButtonGroup, Col, Dropdown, Jumbotron, Row } from "react-bootstrap"
import { CurvedUpArrow, DownloadIcon, ImportIcon, UploadIcon } from "../../components/Images"
import { PrivateLayout } from '../../components/layout'
import { SubHeading } from '../../components/LocaleFields'
import { Seo } from "../../components/navigation"
import SubscriptionCreate from '../../components/SubscriptionCreate'
import SubscriptionList from '../../components/SubscriptionList'
import { GrowRowCol } from "../../components/utils"
import { GlobalStoreContext } from "../../context/GlobalStoreContext"

const IndexPage = () => {
  const { records = {} } = React.useContext(GlobalStoreContext)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return <PrivateLayout>
    <Seo.Subscriptions />
    <Jumbotron className="bg-white px-0 py-0 py-md-4">
      <GrowRowCol className="mb-3">
        <SubHeading className="flex-grow-1">your subscriptions</SubHeading>

        <Dropdown as={ButtonGroup}>
          <Button variant="primary" onClick={handleShow}>Add a Subscription</Button>

          <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

          <Dropdown.Menu>
            <Dropdown.Item href="#backup"><DownloadIcon />Download a backup (CSV)</Dropdown.Item>
            <Dropdown.Item href="#restore"><UploadIcon />Restore from backup (CSV)</Dropdown.Item>
            <Dropdown.Item href="#importFromBank"><ImportIcon />Import from Bank Transactions (CSV)</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </GrowRowCol>

      <SubscriptionCreate  {...{ show, handleClose }} />

      {records && Object.values(records).length > 0
        ? <>
          <SubscriptionList showEdit={true} />
        </>
        : <>
          <Jumbotron className="text-italic text-muted text-center py-3">
            <Row>
              <Col md={{ span: 8, offset: 2 }} className="py-5">
                <h4 className="mb-3">Welcome to your subscriptions!</h4>
                <div className="text-115">
                  <p>This page let&apos;s you create and manage your subscriptions.</p>
                  <p>There&apos;re no subscriptions yet,<br />so let&apos;s &quot;Add a Subscription&quot;...</p>
                </div>
              </Col>
              <Col md={{ span: 2 }} className="d-none d-md-block">
                <CurvedUpArrow />
              </Col>
            </Row>
          </Jumbotron>
        </>}

    </Jumbotron>
  </PrivateLayout>
}

export default IndexPage
