import React from "react"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"

const Toggle = ({ name, value, options, onChange, className, ...props }) => {
    return <ToggleButtonGroup
        type="radio"
        {...{ name, value }}
        onChange={v => onChange({ target: { name, value: v } })}
        className={`d-flex mx-0 my-4 ${className}`}
        {...props}
    >
        {options.map(({ name, value }) => {
            return <ToggleButton key={value} value={value}>{name}</ToggleButton>
        })}
    </ToggleButtonGroup>
}
export default Toggle