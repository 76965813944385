import React, { useState } from "react"
import { Card, Col, Jumbotron, Row } from "react-bootstrap"
import styled from 'styled-components'
import { SimpleBooleanToggle } from '../../components/BooleanToggle'
import { SimpleInput } from '../../components/Input'
import { PrivateLayout } from '../../components/layout'
import { SubHeading } from '../../components/LocaleFields'
import { Seo } from "../../components/navigation"
import { GlobalStoreContext } from '../../context/GlobalStoreContext'
import { ANIMATION_DURATION_SECS, slideInFromBottomRefs } from "../../utils/Animate"

const ProfileImage = styled(({ className = '', ...props }) => <img className={`${className} rounded-circle border border-secondary`} {...props} />)`
height: 75px;
width: 75px;
`
const IndexPage = () => {
  const refProfile = React.useRef(null)
  const refLocalisation = React.useRef(null)
  const refNotifications = React.useRef(null)

  const { store, putProfile } = React.useContext(GlobalStoreContext)

  const [profile, setProfile] = useState(store.profile)
  const { uid, displayName, photoURL, email, emailVerified, phoneNumber, phoneNumberVerified } = profile

  // coming in, on load...
  React.useEffect(() => { setProfile(store.profile) }, [store.profile])

  React.useEffect(() => { slideInFromBottomRefs([refProfile, refLocalisation, refNotifications], ANIMATION_DURATION_SECS * 5) }, [])

  const onChange = ({ target: { name, value } = {} }) => {
    setProfile(s => {
      const newProfile = { ...s, [name]: value }
      putProfile({ ...newProfile })
      return newProfile
    })
  }

  return <PrivateLayout>
    <Seo.Profile />
    <Jumbotron className="bg-white px-0 py-0 py-md-4">
      <Row>

        <Col xs={12} md={6} ref={refProfile}>
          <SubHeading className="my-3">Profile</SubHeading>
          <Card>
            <Card.Body>
              <div className="mb-3 d-flex justify-content-center"><ProfileImage src={photoURL} /></div>
              <SimpleInput {...{ name: 'displayName', state: profile, onChange }} />
            </Card.Body>
          </Card>
        </Col>

        {/* <Col xs={12} md={6} ref={refLocalisation}>
          <SubHeading className="my-3">Localisation</SubHeading>
          <Card>
            <Card.Body>
              <SimpleInput {...{ name: 'language', state: profile, onChange }} />
              <SimpleInput {...{ name: 'timezone', state: profile, onChange }} />
              <SimpleInput {...{ name: 'currency', state: profile, onChange }} />
            </Card.Body>
          </Card>
        </Col> */}

        <Col xs={12} md={6} ref={refNotifications}>
          <SubHeading className="my-3">Notifications</SubHeading>
          <Card>
            <Card.Body>
              <SimpleInput {...{ name: 'email', state: profile, onChange, disabled: true }} />
              <SimpleBooleanToggle {...{ label: 'Receive Weekly updates?', name: 'receiveWeeklyUpdates', state: profile, onChange }} />
              <SimpleBooleanToggle {...{ label: 'Receive Friend updates?', name: 'receiveFriendUpdates', state: profile, onChange, disabled: true }} />
            </Card.Body>
          </Card>
        </Col>

      </Row>

      {/* <pre>{JSON.stringify(profile, null, 2)}</pre> */}

    </Jumbotron>
  </PrivateLayout>
}

export default IndexPage
